// Notice that I have included the mixin here instead of importing it from _globals.scss - TLhis is so that the LoadingOverlay component can be easily reused in other apps without relying on a _globals.scss partial
@mixin flex-display() {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.loading-overlay {
    position: fixed;
    z-index: 999;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    overflow: show;
    margin: auto;
    display:block;

    width:100%;
    height:100%;
    @include flex-display;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerStyles {
    height: 20;
    width: 10%;
    background-color: #111111;
    border-radius: 50;
    margin: 50;
  }

.workDiv {
  font-size: 18px;
  padding: 8px 0;
}

.fillerStyles {
    height: 10px;
    border-radius: inherit;
    text-align: right;
    background-color: #DADED4;
    animation: widthChange 1s ease-in-out 0s 1 ;
    // transition: width 3s ease-in-out;
  }

@keyframes widthChange {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.labelStyles{
    padding: 5;
    color: white;
    font-weight: bold;
  }