@mixin display-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.skillcard {
    margin: 10px 10px;
    width: 80px;
    height: 80px;
    border-radius: 25px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25), -1px -1px 10px 3px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
    @include display-flex();
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  
  .skillcard i {
    padding-top: 10px;
    font-size: 40px;
  }
  
  .skillcard .skillcard_title {
    // text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 12px;
    padding-top: 5px;
    height: 30px;
  }
  
  .skillcard:hover {
    transform: scale(1.2, 1.2);
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25), 
      -1px -1px 10px 6px rgba(0,0,0,0.22);
  }
  
  .title-white {
    color: rgb(0, 0, 0);
  }