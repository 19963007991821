@import "./_globals";

html {
  height:100%;
  overflow-y: scroll;
}

body {
  height:100%;
  padding-right:20px;
  margin:0;
  //font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  //color: $text-color;
  background-color: $background-color;
}