@import "./../_globals";

.skills {
    height: 100%;
    @include display-flex();
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 96%;
    padding: 0 30px;
    // background-color: aqua;

    &__frontend, &__backend, &__others {
        height: 450px;
        width: 300px;
        // background-color: antiquewhite;

        &--header {
            margin: 32px 0;
            font-size: 2em;
            font-weight: 800;
            color:#39603D;
            border-bottom:3px solid #39603D;
        }

        &--body {
            @include display-flex();
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

        }

    }

}

@media (max-width: 700px) {
    .skills {
        width: 96%;
        padding: 0 10px;
    }

}