@import "./../_globals";

$primary: #39603D;
$secondary: #3C403D;
$dark: #212121;
$light: #F3F3F3;

.project {
    height: 100%;
    width: 100%;
    // background-color: #39603D;
    @include display-flex();
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    flex-shrink: 1;
    // background-color: aqua;
}

.card {
    margin: 30px 40px 0 0;
    width: 300px;
    height: 400px;
    perspective: 1000px;
    -webkit-perspective: 1000;
    -moz-transition:all 2s;
    -webkit-transition:all 2s;
    -o-transition:all 2s;
    transition:all 2s;
    // transform-style: preserve-3d;
    z-index: 10;
}

.card.flipped {
    position: relative;
    // transform: ;
    z-index: 1000;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.7);
    transform: translateZ(0) scale(1.7);
    transform-style: preserve-3d;
    // width:380px;
    // height:500px;
    // zoom : 170%;
}
  
  .card__inner {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
  }
  
  .card__inner.is-flipped {
    transform: rotateY(180deg);
  }
  
  
  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
  }
  
  .card__face--front {
    background-image: linear-gradient(to bottom right, $primary, $secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  
  .card__face--front h2 {
    color: #FFF;
    font-size: 32px;
  }
  
  .card__face--back {
    background-color: $light;
    transform: rotateY(180deg);
  }
  
  .card__content {
    width: 100%;
    height: 100%;
    @include display-flex();
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y:auto;
  }
  
  .card__header {
    position: relative;
    padding: 20px 30px 0 20px;
  }
  
  .card__header:after {
    content: '';
    display: block;
    position: absolute;
    height: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding:0;
    background-color: #39603D;
    z-index: -1;
    border-radius: 0px 0px 50% 0px;
  }
  
  .pp {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border: 3px solid #FFF;
    object-fit: cover;
  }
  
  .card__body {
    padding: 0 30px;
  }
  
  .card__body h3 {
    display: inline-block;
    color: $dark;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1px;
  }
  
  .card__body p {
    color: $dark;
    font-size: 12px;
    line-height: 1.4;
    margin: 5px 0;
  }

  .card__body h4 {
    margin: 10px 0 5px 0;
  }

  .btn-weatherReport {
    background: #39603D;
    box-shadow: -3px 3px #888888;
    color:  #ffffff;
    cursor: pointer;
    font-size: 16px;
    padding: 2px 2px 1px 2px;
    margin: auto;
    border: 0;
    transition: all 0.5s;
    border-radius: 3px;
    width: auto;
    position: relative;

    &:hover {
      background: #111111;
      box-shadow: 3px 3px #39603D;
    }
}

.myButton {
	box-shadow:inset 0px 1px 0px 0px #436446;
	background:linear-gradient(to bottom, $primary 5%, $secondary 100%);
	background-color:#39603D;
	border-radius:3px;
	border:1px solid #39603D;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:10px;
	padding:4px 3px;
	text-decoration:none;
  text-shadow:0px 1px 0px #111111;
  margin: 2px 0;
}

.myButton:active {
	position:relative;
	top:1px;
}

@media (max-width: 1600px) {

  .project {
    @include display-flex();
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    flex-shrink: 1;
    // padding-top: 50px;
  }

.card {
  margin: 30px 20px 0 20px;
  width: 200px;
  height: 280px;
  perspective: 1000px;
  -moz-transition:all 2s;
  -webkit-transition:all 2s;
  -o-transition:all 2s;
  transition:all 2s;
}

.card.flipped {
  position: relative;
  z-index: 1000;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.7);
  transform: translateZ(0) scale(1.7);
  transform-style: preserve-3d;
}

.card__body {
  padding: 0 20px;
}

.card__header {
  position: relative;
  padding: 20px 20px 0 20px;
}
}


@media (max-width: 960px) {

  .project {
    padding-bottom: 20px;
  }
    
    .card {
        margin: 30px 30px 0 30px;
        width: 250px;
        height: 333px;
        perspective: 1000px;
        -moz-transition:all 2s;
        -webkit-transition:all 2s;
        -o-transition:all 2s;
        transition:all 2s;
    }

    .card.flipped {
        position: relative;
        transform: scale(1.4);
        z-index: 1000;
    }

}

@media (max-width: 700px) {
    .card.flipped {
        position: relative;
        transform: scale(1.1);
        z-index: 1000;
    }
}