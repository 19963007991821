@import "./_globals";


.main {
    @include display-flex();
    flex-flow: column wrap;
    // background-color: rgb(57, 192, 75);
    min-height: 100vh;
    height: fit-content;
    width: 100%;
}

.header {
    display: none;

    &__menuBtn {
        display: none;
    }
}

.subSection {
    @include display-flex();
    flex-flow: row nowrap;
    // justify-content: flex-start;
    flex-grow: 1;
    height:auto;
    width: 100%;
}

.changesection {
    // background-color: black;
    width: 100%;
}

// .shortNav {
//     display: none;
// }

.nav {
    // position: fixed;
    // top:50%;
    @include display-flex();
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 150px;
    height: 100%;
    // padding-top: 300px;
    margin-left: 30px;
    font-family: 'Sarala', sans-serif;
    font-size: $base-font-size * 2;
    // background-color: #b0f0c0;
    flex-basis: auto;

    &__title {
        padding: 0px;
    }
    
    &__link {
        color: #3C403D;
        text-decoration: none;
        width: 100%;
        text-align: center;
    }

    &__link:hover {
        color: black;
        font-weight: bolder;
        background: url(navBack.png);
        background-repeat: no-repeat;
        background-size:auto;
        background-position: center;
        //background-attachment: fixed;
    }

    .nav__link.click {
        color: black;
        font-weight: bolder;
        background: url(navBack.png);
        background-repeat: no-repeat;
        background-size:auto;
        background-position: center;
        //background-attachment: fixed;
    }
}

.socialMedia {
    @include display-flex();
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 100%;
    padding-top: 80px;

    & > * {
        padding-bottom: 10px;
    }
}

.footer {
    position: relative;
    font-size: 20px;
    bottom: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    a {
        text-decoration: none;
        color: inherit;
        border-bottom: 1px solid;
        &:hover {
        border-bottom: 1px transparent;
        }
    }
  }


@media (max-width: 960px) {
    .nav {
        display: none;
    }

    .header {
        @include display-flex();
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__menuBtn {
            display: inline;
            margin-right: 50px;
        }

        &__icon {
            cursor: pointer;
            //background-color: beige;
        }
    }

    .shortNav {
        @include display-flex();
        flex-direction: column;
        text-align: center;
        width: 100%;
        transition-delay: 1s;
        background-color: hsla(120, 60%, 70%, 0.1);

        & > * {
            margin: 5px 0;
        }
        
    }

    .shortNav__link {
        font-size: 28px;
        color: #3C403D;
        text-decoration: none;
        width: 100%;
    }

    .changesection {
        height: 50%;
    }

    .socialMedia {
        @include display-flex();
        flex-direction: column;
        justify-content: space-between;
        align-self: start;
        height: 50%;
        padding-top: 120px;
    
        & > * {
            padding-bottom: 10px;
        }
    }

    .footer {
        padding-top: 30px;
    }

}

#insta_2:hover, #facebook_2:hover, #linkedin_2:hover, #github_2:hover {
    fill: #39603D;
}

@media (max-width: 700px) {
    .subSection {
        flex-grow: 0;
        @include display-flex();
        flex-flow: column wrap;
        height:100%;
        width: 100%;
        // background-color: aqua;
    }

    .changesection {
        height: auto;
    }

    .header__title {
        padding-left: 0px;
    }

    .header__menuBtn {
        display: inline;
        margin-right: 0px;
    }

    .socialMedia {
        @include display-flex();
        flex-direction: row;
        justify-content: center;
        align-items: center;
        //align-self: start;
        width: 100%;
        padding-top: 0px;
    
        & > * {
            padding: 10px 5px;
        }
    }
}